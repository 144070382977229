import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import putQuiz from "../../../services/Quiz/QuizService";
import { notifyError, notifyTopRight } from "../Common/Toaster";

export default function EditQuiz({ show, data, table, onHide }) {
  const previousType = data.type;
  const previousQue = data.question;
  const previousRelation = data.relation || [];
  const previousLocation = data.location || [];
  const previousSexualOrientation = data.sexualOrientation || [];

  let errorsObj = {
    editQuestion: "",
    editType: "",
    relation: "",
    location: "",
    sexualOrientation: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState();
  const [editQuestion, setEditQuestion] = useState("");
  const [editType, setEditType] = useState("");
  const [relation, setRelation] = useState([]);
  const [location, setLocation] = useState([]);
  const [sexualOrientation, setSexualOrientation] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (show) {
      setEditQuestion(previousQue);
      setEditType(previousType);
      setRelation(previousRelation);
      setLocation(previousLocation);
      setSexualOrientation(previousSexualOrientation);
    }
  }, [show]);

  const handleRelationChange = (e) => {
    const { value, checked } = e.target;
    setRelation((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const handleLocationChange = (e) => {
    const { value, checked } = e.target;
    setLocation((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };
  const handleGenderChange = (e) => {
    const { value, checked } = e.target;
    setSexualOrientation((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setEditQuestion(inputValue);

    if (inputValue.endsWith("@")) {
      setSuggestions(["partner1", "partner2"]);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const atIndex = editQuestion.lastIndexOf("@");
    const newQuestion =
      editQuestion.substring(0, atIndex) + "@" + suggestion + " ";
    setEditQuestion(newQuestion);
    setSuggestions([]);
  };

  function onEditQuiz(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (editType === "") {
      errorObj.editType = "Please select one type!";
      error = true;
    }
    if (editQuestion === "") {
      errorObj.editQuestion = "Please fill this field!";
      error = true;
    }
    if (sexualOrientation.length === 0) {
      errorObj.sexualOrientation = "Please select at least one of these!";
      error = true;
    }


    setErrors(errorObj);
    if (error) {
      return;
    }
    var raw = {
      quizes: [
        {
          quizId: data._id,
          orderNumber: data.order,
          question: editQuestion,
          type: editType,
          relation: relation,
          location: location,
          sexualOrientation: sexualOrientation,
        },
      ],
    };
    putQuiz(raw)
      .then((response) => {
        setEditQuestion("");
        setEditType("");
        table();
        onHide();
        notifyTopRight("Updated Successfully.");
      })
      .catch((error) => {
        notifyError(error?.response?.data?.message);
        if (error?.response?.data?.statusCode === 401) {
          localStorage.clear("closerDetails");
        }
      });
  }

  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <form onSubmit={onEditQuiz}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Edit Quiz</h4>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  {apiError && (
                    <div
                      role="alert"
                      className="fade alert-dismissible fade show alert alert-danger show"
                    >
                      {apiError}
                    </div>
                  )}
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Type</label>
                    <div className="contact-name">
                      <select
                        className="form-control"
                        onChange={(e) => setEditType(e.target.value)}
                        required
                        value={editType}
                      >
                        <option hidden>Select..</option>
                        <option value={"option"}>Option</option>
                        <option value={"comment"}>Comment</option>
                        <option value={"choice"}>Yes/No</option>

                      </select>
                      {errors.editType && (
                        <div className="text-danger fs-12">
                          {errors.editType}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Question</label>
                    {suggestions.length > 0 && (
                      <div className="suggestions">
                        {suggestions.map((suggestion, index) => (
                          <div
                            key={index}
                            className="suggestion"
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion}
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={editQuestion}
                        onChange={handleInputChange}
                        placeholder="Enter question"
                      />
                      {errors.editQuestion && (
                        <div className="text-danger fs-12">
                          {errors.editQuestion}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
              <label className="mb-2 text-black font-w500">
                Relationship Status
              </label>
              <div className="checkbox-container">
                <div className="checkbox-row">
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="dating"
                      name="relationshipStatus"
                      value="0"
                      checked={relation.includes("0")}
                      onChange={handleRelationChange}
                    />
                    <label htmlFor="dating" className="ml-2">
                      {" "}
                      Dating
                    </label>
                  </div>
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="engaged"
                      name="relationshipStatus"
                      value="1"
                      checked={relation.includes("1")}
                      onChange={handleRelationChange}
                    />
                    <label htmlFor="engaged" className="ml-2">
                      {" "}
                      Engaged
                    </label>
                  </div>
                </div>
                <div className="checkbox-row">
                 
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="married"
                      name="relationshipStatus"
                      value="2"
                      checked={relation.includes("2")}
                      onChange={handleRelationChange}
                    />
                    <label htmlFor="married" className="ml-2">
                      {" "}
                      Married
                    </label>
                  </div>
                  <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="not-sure"
                    name="relationshipStatus"
                    value="3"
                    checked={relation.includes("3")}
                    onChange={handleRelationChange}
                  />
                  <label htmlFor="not-sure" className="ml-2">
                    {" "}
                    Not sure
                  </label>
                </div>
                </div>
                
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="mismatchRelation"
                    name="mismatchRelation"
                    value="4"
                    checked={relation.includes("4")}
                    onChange={handleRelationChange}
                  />
                  <label htmlFor="mismatchRelation" className="ml-2">
                    {" "}
                    Mismatch
                  </label>
                </div>
              </div>
              {errors.relation && (
                <div className="text-danger fs-12">{errors.relation}</div>
              )}
            </div>

                  {/* Partner Location Form Group */}

                  <div className="form-group">
                    <label className="mb-2 text-black font-w500">
                      Partner Location
                    </label>
                    <div className="checkbox-container">
                      <div className="checkbox-row">
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="same-house"
                            name="locationStatus"
                            value="0"
                            checked={location.includes("0")}
                            onChange={handleLocationChange}
                          />
                          <label htmlFor="same-house" className="ml-2">
                            {" "}
                            Live together
                          </label>
                        </div>
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="same-city"
                            name="locationStatus"
                            value="1"
                            checked={location.includes("1")}
                            onChange={handleLocationChange}
                          />
                          <label htmlFor="same-city" className="ml-2">
                            {" "}
                            Same city, different houses
                          </label>
                        </div>
                      </div>
                      <div className="checkbox-row">
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="diff-cities-india"
                            name="locationStatus"
                            value="2"
                            checked={location.includes("2")}
                            onChange={handleLocationChange}
                          />
                          <label htmlFor="diff-cities-india" className="ml-2">
                            {" "}
                            Different cities
                          </label>
                        </div>
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="diff-cities-outside-india"
                            name="locationStatus"
                            value="3"
                            checked={location.includes("3")}
                            onChange={handleLocationChange}
                          />
                          <label
                            htmlFor="diff-cities-outside-india"
                            className="ml-2"
                          >
                            {" "}
                            Different countries
                          </label>
                        </div>
                      </div>
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          id="mismatch2"
                          name="mismatch2"
                          value="4"
                          checked={location.includes("4")}
                          onChange={handleLocationChange}
                        />
                        <label htmlFor="mismatch2" className="ml-2">
                          {" "}
                          Mismatch
                        </label>
                      </div>
                    </div>
                    {errors.location && (
                      <div className="text-danger fs-12">{errors.location}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-2 text-black font-w500">
                      Sexual Orientation
                    </label>
                    <div className="checkbox-container">
                      <div className="checkbox-row">
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="Straight"
                            name="genderStatus"
                            value="Straight"
                            checked={sexualOrientation.includes("Straight")}
                            onChange={handleGenderChange}
                          />
                          <label htmlFor="Straight" className="ml-2">
                            {" "}
                            Straight
                          </label>
                        </div>
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="Gay"
                            name="genderStatus"
                            value="Gay"
                            checked={sexualOrientation.includes("Gay")}
                            onChange={handleGenderChange}
                          />
                          <label htmlFor="Gay" className="ml-2">
                            {" "}
                            Gay
                          </label>
                        </div>
                      </div>
                      <div className="checkbox-row">
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="Lesbians"
                            name="genderStatus"
                            value="Lesbian"
                            checked={sexualOrientation.includes("Lesbian")}
                            onChange={handleGenderChange}
                          />
                          <label htmlFor="Lesbians" className="ml-2">
                            {" "}
                            Lesbian
                          </label>
                        </div>
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="Male & non-binary"
                            name="genderStatus"
                            value="Male & non-binary"
                            checked={sexualOrientation.includes(
                              "Male & non-binary"
                            )}
                            onChange={handleGenderChange}
                          />
                          <label htmlFor="Male & non-binary" className="ml-2">
                            {" "}
                            Male & non-binary
                          </label>
                        </div>
                      </div>
                      <div className="checkbox-row">
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="female & non-binary"
                            name="genderStatus"
                            value="Female & non-binary"
                            checked={sexualOrientation.includes(
                              "Female & non-binary"
                            )}
                            onChange={handleGenderChange}
                          />
                          <label htmlFor="female & non-binary" className="ml-2">
                            {" "}
                            Female & non-binary
                          </label>
                        </div>
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="both non-binary"
                            name="genderStatus"
                            value="Both non-binary"
                            checked={sexualOrientation.includes(
                              "Both non-binary"
                            )}
                            onChange={handleGenderChange}
                          />
                          <label htmlFor="both non-binary" className="ml-2">
                            {" "}
                            Both non-binary
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.sexualOrientation && (
                      <div className="text-danger fs-12">
                        {errors.sexualOrientation}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
