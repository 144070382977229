import React, { useEffect, useRef, useState } from "react";
import addPhoto, { pushNotification } from "../../services/AuthService";
import { getAllUsers } from "../../services/User/UserService";
import { MultiSelect } from "./MultiSelect";

import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";

export default function PushNotification(props) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 0;
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  let errorsObj = { title: "", body: "", type: "", selected: [] };
  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  const formRef = useRef(null);

  //////// its creates option list //////
  const options = [
    ...data?.map((item, i) => {
      return { value: item?._id, label: item?.name + " " + item?.lastName };
    }),
  ];

  //// define selected ids in an array

  const totalIds = [...selected];
  const ids = totalIds?.map((item, i) => {
    console.log(item?.value, "id...............");
    return item?.value;
  });

  let albumName = "closer";

  const handleInputChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      addPhoto(selectedFile, albumName)
        .then((response) => {
          console.log(response.Location, "image loaction");
          setImage(response.Location);
        })
        .catch((error) => {
          console.log(error, "image upload error");
        });
    }
  };

  async function handleSubmit() {
    // e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (title === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (body === "") {
      errorObj.body = "Body is Required";
      error = true;
    }
    if (selected.length === 0) {
      errorObj.selected = "Please select an user";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    pushNotification(title, body, ids, type, image)
      .then(() => {
        notifyTopRight("Sent Successfully.");
        setTitle("");
        setBody("");
        setType("");
        setImage("");
        // Reset the form
        if (formRef.current) {
          formRef.current.reset();
        }
        setSelected([]);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error.response?.data?.message);
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }

  useEffect(() => {
    setLoader(true);
    getAllUsers(currentPage, limit, search)
      .then((response) => {
        console.log(response, "user data response");
        setData(response.data.data.users);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        // notifyError(error.response?.data?.data);
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/login");
        }
      });
  }, []);
  return (
    <div>
      <PageTitle activeMenu="Push Notification" motherMenu="Notification" />
      <div className="col-lg-8 col-md-7 ">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>

          <form ref={formRef}>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Image</strong>
              </label>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Title</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
              />
              {errors.title && (
                <div className="text-danger fs-12">{errors.title}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Body</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Write something here.."
              />
              {errors.body && (
                <div className="text-danger fs-12">{errors.body}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Send To</strong>
              </label>

              <MultiSelect
                className="form-control"
                options={options}
                value={selected}
                onChange={setSelected}
                required
              />
              {errors.selected && (
                <div className="text-danger fs-12">{errors.selected}</div>
              )}
            </div>

            <div className="text-center">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={handleSubmit}
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      {loader && <Spinner />}
    </div>
  );
}
