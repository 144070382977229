/// auth service

export const LOGIN = 'admin/auth/login';

export const CHANGE_PASSWORD = 'admin/auth/changePassword';

export const LOGOUT = 'admin/auth/logout';

export const DASHBOARD = 'admin/auth/dashboard';

export const POST_NOTIFICATION = 'admin/user/notification';

export const TRIGGER_NOW = 'admin/user/triggerNow';