import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const QUIZ_DATA_ACTION = '[data action] data action';

export function signupAction(email, password, history) {
    return (dispatch) => {
        // signUp(email, password)
        // .then((response) => {
        //     saveTokenInLocalStorage(response.data);
        //     runLogoutTimer(
        //         dispatch,
        //         response.data.expiresIn * 1000,
        //         history,
        //     );
        //     dispatch(confirmedSignupAction(response.data));
        //     history.push('/');
        // })
        // .catch((error) => {
        //     const errorMessage = formatError(error.response.data);
        //     dispatch(signupFailedAction(errorMessage));
        // });
    };
}

export function logout(history) {
    localStorage.removeItem('userDetails');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(loginConfirmedAction(response.data));
				history.push('/dashboard');
				//window.location.reload();
                
				//history.pushState('/index');
                
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}


export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export function QuizDataAction(status) {
    return {
        type: QUIZ_DATA_ACTION,
        payload: status,
    };
}
