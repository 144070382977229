export const GET_QUIZ = 'admin/user/quiz';

export const POST_QUIZ = 'admin/user/quiz';

export const PUT_QUIZ = 'admin/user/quiz';

export const DELETE_QUIZ = 'admin/user/quiz';

export const GET_FIRST_FREQUENCY = 'admin/user/firstFrequency';

export const FIRST_FREQUENCY = 'admin/user/firstFrequency';

export const GET_FREQUENCY = 'admin/user/frequency';

export const PUT_FREQUENCY = 'admin/user/frequency';






