import instance from "../Instance";
import {
  FIRST_FREQUENCY_CARD,
  GET_CARDS,
  GET_FIRST_FREQUENCY_CARD,
  GET_FREQUENCY_CARD,
  PUT_FREQUENCY_CARD,
} from "./CardEndPoints";

export function getAllCards(limit, currentPage) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_CARDS + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}

export function postCard(payload) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(GET_CARDS, payload, {
    headers: myHeaders,
  });
}
export default function putCard(postData) {
  console.log(postData, " updated data");
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = { type, question };
  return instance.put(GET_CARDS, postData, {
    headers: myHeaders,
  });
}
export function deleteCard(cardId) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = { type, question };
  return instance.delete(GET_CARDS + `?cardId=${cardId}`, {
    headers: myHeaders,
  });
}

export function getFirstFrequencyCard(type) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_FIRST_FREQUENCY_CARD + `?type=${type}`, {
    headers: myHeaders,
  });
}
export function postFistFrequencyCard(frequency, type) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { frequency: frequency, type: type };
  return instance.put(FIRST_FREQUENCY_CARD, postData, {
    headers: myHeaders,
  });
}

export function getFrequencyCard(type) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_FREQUENCY_CARD + `?type=${type}`, {
    headers: myHeaders,
  });
}

export function postFrequencyCard(frequency, type) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { frequency: frequency, type: type };
  return instance.put(PUT_FREQUENCY_CARD, postData, {
    headers: myHeaders,
  });
}
