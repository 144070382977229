import instance from "../Instance";
import {
  FIRST_FREQUENCY_HORNY_CARD,
  GET_FIRST_FREQUENCY_HORNY_CARD,
  GET_FREQUENCY_HORNY_CARD,
  GET_HORNY_CARDS,
  PUT_FREQUENCY_HORNY_CARD,
} from "./HornyCardEndPoint";

export function getAllHorny(limit, currentPage) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_HORNY_CARDS + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}

export function postHorny(payload) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(GET_HORNY_CARDS, payload, {
    headers: myHeaders,
  });
}
export default function putHorny(postData) {
  console.log(postData, " updated data");
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = { type, question };
  return instance.put(GET_HORNY_CARDS, postData, {
    headers: myHeaders,
  });
}
export function deleteHorny(cardId) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = { type, question };
  return instance.delete(GET_HORNY_CARDS + `?quizId=${cardId}`, {
    headers: myHeaders,
  });
}

export function getFirstFrequencyHorny() {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_FIRST_FREQUENCY_HORNY_CARD, {
    headers: myHeaders,
  });
}
export function postFistFrequencyHorny(frequency) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { frequency: frequency };
  return instance.put(FIRST_FREQUENCY_HORNY_CARD, postData, {
    headers: myHeaders,
  });
}

export function getFrequencyHorny() {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_FREQUENCY_HORNY_CARD, {
    headers: myHeaders,
  });
}

export function postFrequencyHorny(frequency) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { frequency: frequency };
  return instance.put(PUT_FREQUENCY_HORNY_CARD, postData, {
    headers: myHeaders,
  });
}
