import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { toast, ToastContainer } from "react-toastify";

import dummy from "../../images/Profile_avatar_placeholder_large.png";
import {
  blockUser,
  deleteUser,
  getAllUsers,
} from "../../services/User/UserService";
import Pagination from "../components/Common/Pagination";
import Spinner from "../components/Common/Spinner";

export default function UserList(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [listLength, setListlenght] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);

  // const limit = 10;
  const imgUrl = process.env.REACT_APP_IMAGE_BASEURL;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function getTableData() {
    setLoader(true);
    getAllUsers(currentPage, limit, search)
      .then((response) => {
        console.log(response.data);
        setUsers(response.data.data.users);

        setLoader(false);
        const total = response.data.data.count;
        setPageCount(Math.ceil(total / limit));
        setListlenght(response.data.data.count);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }

  function onDelete(id) {
    setLoader(true);
    deleteUser(id)
      .then((response) => {
        getTableData();
        notifyTopRight(response.data.message);
        console.log(response);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.response, "helooo");
        setLoader(false);
        notifyError(error.response.data.data);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }
  function onAction(id, toggle) {
    setLoader(true);
    blockUser(id, toggle)
      .then((response) => {
        notifyTopRight(response.data.message);
        getTableData();
        setLoader(false);

        console.log(response);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
        notifyError(error.response?.data?.data);
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage, search]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Users List" motherMenu="User Management" />
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-8" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      // onKeyDown={(e) => {
                      //   console.log(e.key);
                      //   if (e.key === "Enter") {
                      //     handleFetch();
                      //     // setCurrentPage(0);
                      //   }
                      // }}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              responsive
              className="mostly-customized-scrollba"
              style={{ height: "500px" }}
            >
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>IMAGE</strong>
                  </th>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>PHONE NUMBER</strong>
                  </th>
                  <th className="text-center">
                    <strong>ACTIVITY STATUS</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <img
                        src={item.profilePic ? imgUrl + item.profilePic : dummy}
                        width={70}
                        height={70}
                        className="rounded"
                      />
                    </td>
                    <td
                    //   onClick={() => (

                    //     props.history.push("/user-details")
                    //   )}
                    //   style={{ cursor: "pointer" }}
                    >
                      {item.name}
                    </td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>

                    <td className="text-center">
                      {item.isBlocked ? (
                        <Badge variant="danger light">Deactive</Badge>
                      ) : (
                        <Badge variant="success light">Active</Badge>
                      )}
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {item.isBlocked ? (
                            <Dropdown.Item
                              onClick={() => onAction(item._id, 0)}
                            >
                              Enable
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              onClick={() => onAction(item._id, 1)}
                            >
                              Disable
                            </Dropdown.Item>
                          )}

                          <Dropdown.Item onClick={() => onDelete(item._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length === 0 ? (
              ""
            ) : (
              <div className="card-footer clearfix">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
                <div>
                  <label className="mr-2">Result Per page : </label>
                  <select
                    className=""
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>50</option>
                    <option>100</option>
                  </select>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
