import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import CountUp from "react-countup";
import { dashboard } from "../../../services/AuthService";

const Home = (props) => {
  const [users, setUsers] = useState("");

  function getData() {
    dashboard()
      .then((response) => {
        console.log(response.data);
        setUsers(response.data.data.users);
      })
      .catch((error) => {
        console.log(error, "error");

        if (error?.response?.data?.statusCode === 401) {
          localStorage.removeItem("closerDetails");
          props.history.push("/");
        }
      });
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Dashboard</h2>
          <p className="mb-0">Welcome to Closer Admin!</p>
        </div>
      </div>
      <div className="row">
        {/* <div className='col-xl-3 col-xxl-4 col-lg-6 col-sm-6'>
          <div className='widget-stat card bg-primary'>
            <div className='card-body  p-4'>
              <div className='media'>
                <span className='mr-3'>
                  <i className='flaticon-381-calendar-1'></i>
                </span>
                <div className='media-body text-white text-right'>
                  <p className='mb-1'>Total Virtual consultation </p>
                  <small>(Booked)</small>

                  <h3 className='text-white'>76</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3 col-xxl-4 col-lg-6 col-sm-6'>
          <div className='widget-stat card bg-primary'>
            <div className='card-body p-4'>
              <div className='media'>
                <span className='mr-3'>
                  <i className='flaticon-381-diamond'></i>
                </span>
                <div className='media-body text-white text-right'>
                  <p className='mb-1'>Total Project Inquiry Placed</p>
                  <h3 className='text-white'>56</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3 col-xxl-4 col-lg-6 col-sm-6'>
          <div className='widget-stat card bg-primary'>
            <div className='card-body p-4'>
              <div className='media'>
                <span className='mr-3'>
                  <i className='flaticon-381-heart'></i>
                </span>
                <div className='media-body text-white text-right'>
                  <p className='mb-1'>Total Interior Designers</p>
                  <small>(Registered)</small>
                  <h3 className='text-white'>78</h3>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-primary">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-white text-right">
                  <p className="mb-1">Total User</p>
                  <small>(Registered)</small>
                  <h3 className="text-white">
                    <span className="counter ml-0">
                      <CountUp start={0} end={users} duration={5} />
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
