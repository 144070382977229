import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import UserList from "./pages/UserList";
import QueListing from "./pages/QueListing";
import QueCreate from "./pages/QueCreate";
import FreQuency from "./pages/FreQuency";
import PushNotification from "./pages/PushNotification";
import ImageCardListing from "./pages/ImageCardManagement/ImageCardListing";
import ImageCardForm from "./pages/ImageCardManagement/ImageCardForm";
import ImageCardFrequency from "./pages/ImageCardManagement/ImageCardFrequency";
import QuoteCardListing from "./pages/QuoteCardManagement/QuoteCardListing";
import QuoteCardForm from "./pages/QuoteCardManagement/QuoteCardForm";
import QuoteCardFrequency from "./pages/QuoteCardManagement/QuoteCardFrequency";
import HornyCradListing from "./pages/HornyCardManagement/HornyCradListing";
import HornyCardForm from "./pages/HornyCardManagement/HornyCardForm";
import HornyCardFrequency from "./pages/HornyCardManagement/HornyCardFrequency";
import FeedbackManagement from "./pages/FeedbackManagement";
import FeedbackDetails from "./pages/FeedbackDetails";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    /// user
    { url: "user-management", component: UserList },
    ///QnA
    { url: "QnA-listing", component: QueListing },
    { url: "QnA-create", component: QueCreate },
    { url: "QnA-frequency", component: FreQuency },

    ////image card
    { url: "image-card-listing", component: ImageCardListing },
    { url: "image-card-create", component: ImageCardForm },
    { url: "image-card-frequency", component: ImageCardFrequency },
    ////quote card
    { url: "quote-card-listing", component: QuoteCardListing },
    { url: "quote-card-create", component: QuoteCardForm },
    { url: "quote-card-frequency", component: QuoteCardFrequency },

    ////quote card
    { url: "horny-card-listing", component: HornyCradListing },
    { url: "horny-card-create", component: HornyCardForm },
    { url: "horny-card-frequency", component: HornyCardFrequency },

    ///feedback list
    { url: "feedback-list", component: FeedbackManagement },
    { url: "feedback-details", component: FeedbackDetails },



    ///push notification
    { url: "push-notification", component: PushNotification },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
