import instance from "../Instance";
import { BLOCK_USERS, DELETE_USERS, GET_USERS } from "./UserEndpoints";

export function getAllUsers(currentPage, limit, search) {
  // console.log(search, " search");
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_USERS + `?page=${currentPage}&limit=${limit}&search=${search}`,
    {
      headers: myHeaders,
    }
  );
}

export function deleteUser(id) {
  const data = localStorage.getItem("closerDetails");

  return instance.delete(DELETE_USERS + `?id=${id}`, {
    headers: {
      Authorization: `Bearer ${data}`,
    },
  });
}

export function blockUser(id,toggle) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { userId:id ,toggle};
  return instance.put(BLOCK_USERS, postData, {
    headers: myHeaders,
  });
}


