import React, { useState } from "react";

import { postQuiz } from "../../../services/Quiz/QuizService";
import PageTitle from "../../layouts/PageTitle";
import Spinner from "../../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../../components/Common/Toaster";
import { postCard } from "../../../services/ImageCard/ImageCardService";
import { postQuote } from "../../../services/Quote/QuoteService";

export default function QuoteCardForm(props) {
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("");
  const [text, setText] = useState("");
  const [relation, setRelation] = useState([]);
  const [location, setLocation] = useState([]);
  const [sexualOrientation, setSexualOrientation] = useState([]);
  const [category, setCategory] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleRelationChange = (e) => {
    const { value, checked } = e.target;
    setRelation((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const handleLocationChange = (e) => {
    const { value, checked } = e.target;
    setLocation((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };
  const handleGenderChange = (e) => {
    const { value, checked } = e.target;
    setSexualOrientation((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };
  let errorsObj = {
    text: "",
    relation: "",
    location: "",
    sexualOrientation: "",
    category: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  function onCreate(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (text === "") {
      errorObj.text = "Please fill this field!";
      error = true;
    }
    // if (relation.length === 0) {
    //   errorObj.relation = "Please select at least one of these!";
    //   error = true;
    // }
    // if (location.length === 0) {
    //   errorObj.location = "Please select at least one of these!";
    //   error = true;
    // }
    if (sexualOrientation.length === 0) {
      errorObj.sexualOrientation = "Please select at least one of these!";
      error = true;
    }
    if (category === "") {
      errorObj.category = "Please select at least one of these!";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    const payload = {
      text: text,
      relation: relation,
      location: location,
      sexualOrientation: sexualOrientation,
      category: category,
    };
    postQuote(payload)
      .then((response) => {
        setType(type);
        setLoader(false);
        setText("");
        notifyTopRight("Created Successfully.");
        console.log(response, "login reponse");
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error.response?.data?.message);
        console.log(error, "Login error");
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setText(inputValue);
    if (inputValue.endsWith("@")) {
      setSuggestions(["partner1", "partner2"]);
    } else {
      setSuggestions([]);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    const atIndex = text.lastIndexOf("@");
    const newQuestion = text.substring(0, atIndex) + "@" + suggestion + " ";
    setText(newQuestion);
    setSuggestions([]);
  };
  return (
    <div>
      <PageTitle activeMenu="Quote Card Create" motherMenu="Quote Cards" />
      <div className="col-lg-12 mb-5">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Create</h3>
          </div>
          <form onSubmit={onCreate}>
            <div className="form-group">
              <label className="text-black font-w500">Description</label>
              {suggestions.length > 0 && (
                <div className="suggestions">
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className="suggestion"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </div>
                  ))}
                </div>
              )}
              <input
                type="text"
                className="form-control"
                value={text}
                onChange={handleInputChange}
              />
              {errors.text && (
                <div className="text-danger fs-12">{errors.text}</div>
              )}
            </div>
            <div className="form-group">
              <label className="text-black font-w500">Category</label>

              <div className="">
                <select
                  className="form-control"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                >
                  <option hidden>Select..</option>
                  <option value={"dateNights"}>Date Night Ideas</option>
                  <option value={"cornersideChats"}>Cornerside chats</option>
                  <option value={"appNudge"}>App Nudge</option>
                  <option value={"loveTips"}>Love Tips or Quotes</option>
                </select>
              </div>
              {errors.category && (
                <div className="text-danger fs-12">{errors.category}</div>
              )}
            </div>

            <div className="form-group">
              <label className="mb-2 text-black font-w500">
                Relationship Status
              </label>
              <div className="checkbox-container">
                <div className="checkbox-row">
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="dating"
                      name="relationshipStatus"
                      value="0"
                      checked={relation.includes("0")}
                      onChange={handleRelationChange}
                    />
                    <label htmlFor="dating" className="ml-2">
                      {" "}
                      Dating
                    </label>
                  </div>
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="engaged"
                      name="relationshipStatus"
                      value="1"
                      checked={relation.includes("1")}
                      onChange={handleRelationChange}
                    />
                    <label htmlFor="engaged" className="ml-2">
                      {" "}
                      Engaged
                    </label>
                  </div>
                </div>
                <div className="checkbox-row">
                 
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="married"
                      name="relationshipStatus"
                      value="2"
                      checked={relation.includes("2")}
                      onChange={handleRelationChange}
                    />
                    <label htmlFor="married" className="ml-2">
                      {" "}
                      Married
                    </label>
                  </div>
                  <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="not-sure"
                    name="relationshipStatus"
                    value="3"
                    checked={relation.includes("3")}
                    onChange={handleRelationChange}
                  />
                  <label htmlFor="not-sure" className="ml-2">
                    {" "}
                    Not sure
                  </label>
                </div>
                </div>
                
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="mismatchRelation"
                    name="mismatchRelation"
                    value="4"
                    checked={relation.includes("4")}
                    onChange={handleRelationChange}
                  />
                  <label htmlFor="mismatchRelation" className="ml-2">
                    {" "}
                    Mismatch
                  </label>
                </div>
              </div>
              {errors.relation && (
                <div className="text-danger fs-12">{errors.relation}</div>
              )}
            </div>

            {/* Partner Location Form Group */}
            <div className="form-group">
              <label className="mb-2 text-black font-w500">
                Partner Location
              </label>
              <div className="checkbox-container">
                <div className="checkbox-row">
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="same-house"
                      name="locationStatus"
                      value="0"
                      checked={location.includes("0")}
                      onChange={handleLocationChange}
                    />
                    <label htmlFor="same-house" className="ml-2">
                      {" "}
                      Live together
                    </label>
                  </div>
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="same-city"
                      name="locationStatus"
                      value="1"
                      checked={location.includes("1")}
                      onChange={handleLocationChange}
                    />
                    <label htmlFor="same-city" className="ml-2">
                      {" "}
                      Same city, different houses
                    </label>
                  </div>
                </div>
                <div className="checkbox-row">
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="diff-cities-india"
                      name="locationStatus"
                      value="2"
                      checked={location.includes("2")}
                      onChange={handleLocationChange}
                    />
                    <label htmlFor="diff-cities-india" className="ml-2">
                      {" "}
                      Different cities
                    </label>
                  </div>
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="diff-cities-outside-india"
                      name="locationStatus"
                      value="3"
                      checked={location.includes("3")}
                      onChange={handleLocationChange}
                    />
                    <label htmlFor="diff-cities-outside-india" className="ml-2">
                      {" "}
                      Different countries
                    </label>
                  </div>
                </div>
                <div className="checkbox-item">
                  <input
                    type="checkbox"
                    id="mismatch"
                    name="mismatch"
                    value="4"
                    checked={location.includes("4")}
                    onChange={handleLocationChange}
                  />
                  <label htmlFor="mismatch" className="ml-2">
                    {" "}
                    Mismatch
                  </label>
                </div>
              </div>
              {errors.location && (
                <div className="text-danger fs-12">{errors.location}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 text-black font-w500">
                Sexual Orientation
              </label>
              <div className="checkbox-container">
                <div className="checkbox-row">
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="Straight"
                      name="genderStatus"
                      value="Straight"
                      checked={sexualOrientation.includes("Straight")}
                      onChange={handleGenderChange}
                    />
                    <label htmlFor="Straight" className="ml-2">
                      {" "}
                      Straight
                    </label>
                  </div>
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="Gay"
                      name="genderStatus"
                      value="Gay"
                      checked={sexualOrientation.includes("Gay")}
                      onChange={handleGenderChange}
                    />
                    <label htmlFor="Gay" className="ml-2">
                      {" "}
                      Gay
                    </label>
                  </div>
                </div>
                <div className="checkbox-row">
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="Lesbians"
                      name="genderStatus"
                      value="Lesbian"
                      checked={sexualOrientation.includes("Lesbian")}
                      onChange={handleGenderChange}
                    />
                    <label htmlFor="Lesbians" className="ml-2">
                      {" "}
                      Lesbian
                    </label>
                  </div>
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="Male & non-binary"
                      name="genderStatus"
                      value="Male & non-binary"
                      checked={sexualOrientation.includes("Male & non-binary")}
                      onChange={handleGenderChange}
                    />
                    <label htmlFor="Male & non-binary" className="ml-2">
                      {" "}
                      Male & non-binary
                    </label>
                  </div>
                </div>
                <div className="checkbox-row">
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="female & non-binary"
                      name="genderStatus"
                      value="Female & non-binary"
                      checked={sexualOrientation.includes(
                        "Female & non-binary"
                      )}
                      onChange={handleGenderChange}
                    />
                    <label htmlFor="female & non-binary" className="ml-2">
                      {" "}
                      Female & non-binary
                    </label>
                  </div>
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="both non-binary"
                      name="genderStatus"
                      value="Both non-binary"
                      checked={sexualOrientation.includes("Both non-binary")}
                      onChange={handleGenderChange}
                    />
                    <label htmlFor="both non-binary" className="ml-2">
                      {" "}
                      Both non-binary
                    </label>
                  </div>
                </div>
              </div>
              {errors.sexualOrientation && (
                <div className="text-danger fs-12">
                  {errors.sexualOrientation}
                </div>
              )}
            </div>
            <div className="text-right ">
              <button
                type="submit"
                className="btn btn-primary text-white   mr-3"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {loader && <Spinner />}
    </div>
  );
}
