import instance from "../Instance";
import {
  DELETE_QUIZ,
  FIRST_FREQUENCY,
  GET_FIRST_FREQUENCY,
  GET_FREQUENCY,
  GET_QUIZ,
  POST_QUIZ,
  PUT_FREQUENCY,
  PUT_QUIZ,
} from "./QuizApiEndpoints";

export function getAllQuiz(limit, currentPage) {
  // console.log(search, " search");
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_QUIZ + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}

export function postQuiz(
  type,
  question,
  relation,
  location,
  sexualOrientation
) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = {
    type,
    question,
    relation: relation,
    location: location,
    sexualOrientation,
  };
  return instance.post(POST_QUIZ, postData, {
    headers: myHeaders,
  });
}
export default function putQuiz(postData) {
  console.log(postData, " updated data");
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = { type, question };
  return instance.put(PUT_QUIZ, postData, {
    headers: myHeaders,
  });
}
export function deleteQuiz(quizId) {
  // console.log(postData," updated data")
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = { type, question };
  return instance.delete(DELETE_QUIZ + `?quizId=${quizId}`, {
    headers: myHeaders,
  });
}

export function getFirstFrequency() {
  // console.log(search, " search");
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_FIRST_FREQUENCY, {
    headers: myHeaders,
  });
}
export function postFistFrequency(frequency) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { time: frequency };
  return instance.post(FIRST_FREQUENCY, postData, {
    headers: myHeaders,
  });
}

export function getFrequency() {
  // console.log(search, " search");
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_FREQUENCY, {
    headers: myHeaders,
  });
}

export function postFrequency(frequency) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { frequency: frequency };
  return instance.put(PUT_FREQUENCY, postData, {
    headers: myHeaders,
  });
}
