import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Dropdown } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import Spinner from "../../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../../components/Common/Toaster";
import Pagination from "../../components/Common/Pagination";
import putQuote, {
  deleteQuote,
  getAllQuote,
} from "../../../services/Quote/QuoteService";
import EditQuote from "../../components/modal/EditQuote";
import putHorny, {
  deleteHorny,
  getAllHorny,
} from "../../../services/HornyCard/HornyCardService";
import EditHorny from "../../components/modal/EditHorny";
import ConfirmationModal from "../../components/modal/ConfirmationModal";

export default function HornyCradListing(props) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [orderChanged, setOrderChanged] = useState(false);
  const [show, setShow] = useState(false);
  const [currentQuizData, setCurrentQuizData] = useState({});
  const [listLength, setListlenght] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  // const limit = 10;
  const [limit, setLimit] = useState(100);
  const [confirmModal, setConfirmModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    // var updatedList = [...property];
    var updatedList = [...data];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    updatedList.map((data, index) => (data.order = index + 1));
    setOrderChanged(true);
    // setProperty(updatedList);
    setData(updatedList);
    console.log(updatedList, "updatedList.......");
  };

  const handleFetch = async () => {
    setLoader(true);
    try {
      const response = await getAllHorny(limit, currentPage);
      console.log(response, "success");
      setData(response.data.data?.quiz);
      setListlenght(response.data.data.count);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setLoader(false);
      if (response.data.data?.quiz < limit) {
        setCurrentPage(0);
      }
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error.response?.data?.statusCode === 401) {
        localStorage.removeItem("closerDetails");
        props.history.push("/");
      }
    }
  };

  const sortMatch = async () => {
    setLoader(true);
    var raw = {
      quizes: [
        ...data.map((item, i) => {
          return {
            quizId: item._id,
            order: item?.order,
            question: item?.question,
            relation: item?.relation,
            location: item?.location,
            sexualOrientation: item?.sexualOrientation,
            category: item?.category,
            type: item?.type,

          };
        }),
      ],
    };
    try {
      const response = await putHorny(raw);
      console.log(response, "success");
      setOrderChanged(false);
      handleFetch();
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error.response?.data?.statusCode === 401) {
        localStorage.clear("closerDetails");
        props.history.push("/");
      }
    }
  };


  const onDelete = (id) => {
    setItemToDelete(id);
    setConfirmModal(true);
  };
  const handleConfirmDelete = async () => {
    setLoader(true);
    try {
      const response = await deleteHorny(itemToDelete);
      console.log(response, "success");
      notifyTopRight("Deleted successfully.");
      handleFetch();
      setConfirmModal(false);
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      notifyError("Something went wrong!");
      setLoader(false);
      if (error.response?.data?.statusCode === 401) {
        localStorage.clear("closerDetails");
        props.history.push("/");
      }
    }
  };
  useEffect(() => {
    handleFetch();
  }, [currentPage, limit]);
  return (
    <div>
      <PageTitle activeMenu="Horny Card List" motherMenu="Horny Cards" />
      <div className="card card-info">
        <div className="card-header" style={{ background: "#tranparent" }}>
          <h3 className="card-title">Listings</h3>
          <div className="card-tools">
            <div>
              {orderChanged && (
                <button
                  className="btn btn-secondary mr-3 fw-bold"
                  onClick={() => {
                    sortMatch();
                  }}
                >
                  Save Change Order
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="card-body table-responsive p-4">
          <DragDropContext onDragEnd={handleDrop}>
            <table className="table table-hover text-nowrap ">
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>Order</strong>
                  </th>
                  <th>
                    <strong>type</strong>
                  </th>
                  <th>
                    <strong>question</strong>
                  </th>
                  <th>
                    <strong>action</strong>
                  </th>
                </tr>
              </thead>
              <Droppable droppableId="droppable-1">
                {(provider) => (
                  <tbody
                    ref={provider.innerRef}
                    {...provider.droppableProps}
                    className=""
                  >
                    {data?.map((item, index) => (
                      <Draggable
                        key={item?._id}
                        draggableId={item?._id}
                        index={index}
                      >
                        {(provider) => (
                          <tr
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                          >
                            <td {...provider.dragHandleProps}>{index+1}</td>
                            <td {...provider.dragHandleProps}>{item?.type==="choice"?"Yes/No":item?.type}</td>

                            <td {...provider.dragHandleProps}>
                              {item?.question}
                            </td>
                            <td {...provider.dragHandleProps}>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="primary light"
                                  className="light sharp btn btn-primary i-false"
                                >
                                  {svg1}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setCurrentQuizData(item);
                                      setShow(true);
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => onDelete(item._id)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provider.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
        </div>
        {data?.length === 0 && !loader ? (
          <div className="justify-content-center d-flex my-5 ">
            Sorry, Data Not Found!
          </div>
        ) : (
          ""
        )}
        {data?.length === 0 ? (
          ""
        ) : (
          <div className="card-footer clearfix">
            <div className="d-flex justify-content-between align-items-center">
              <div className="dataTables_info">
                Showing {currentPage * limit + 1} to{" "}
                {listLength > (currentPage + 1) * limit
                  ? (currentPage + 1) * limit
                  : listLength}{" "}
                of {listLength} entries
              </div>
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
            <div>
              <label className="mr-2">Result Per page : </label>
              <select
                className=""
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
          </div>
        )}
      </div>
      {loader && <Spinner />}
      <EditHorny
        show={show}
        data={currentQuizData}
        table={handleFetch}
        onHide={() => setShow(false)}
      />
      <ConfirmationModal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
}
