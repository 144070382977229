import instance from "../Instance";

import { GET_QUOTE } from "./QuoteEndPoints";

export function getAllQuote(limit, currentPage) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_QUOTE + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}

export function postQuote(payload) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(GET_QUOTE, payload, {
    headers: myHeaders,
  });
}
export default function putQuote(postData) {
  console.log(postData, " updated data");
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = { type, question };
  return instance.put(GET_QUOTE, postData, {
    headers: myHeaders,
  });
}
export function deleteQuote(cardId) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = { type, question };
  return instance.delete(GET_QUOTE + `?quoteId=${cardId}`, {
    headers: myHeaders,
  });
}

// export function getFirstFrequencyCard() {
//   const data = localStorage.getItem("closerDetails");
//   const myHeaders = {
//     Authorization: `Bearer ${data}`,
//   };
//   return instance.get(GET_FIRST_FREQUENCY_CARD, {
//     headers: myHeaders,
//   });
// }
// export function postFistFrequencyCard(frequency) {
//   const data = localStorage.getItem("closerDetails");
//   const myHeaders = {
//     Authorization: `Bearer ${data}`,
//   };
//   const postData = { time: frequency };
//   return instance.put(FIRST_FREQUENCY_CARD, postData, {
//     headers: myHeaders,
//   });
// }

// export function getFrequencyCard() {
//   const data = localStorage.getItem("closerDetails");
//   const myHeaders = {
//     Authorization: `Bearer ${data}`,
//   };
//   return instance.get(GET_FREQUENCY_CARD, {
//     headers: myHeaders,
//   });
// }

// export function postFrequencyCard(frequency) {
//   const data = localStorage.getItem("closerDetails");
//   const myHeaders = {
//     Authorization: `Bearer ${data}`,
//   };
//   const postData = { frequency: frequency };
//   return instance.put(PUT_FREQUENCY_CARD, postData, {
//     headers: myHeaders,
//   });
// }
