import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/Common/Spinner";

import dummy from "../../images/Profile_avatar_placeholder_large.png";
import { Link } from "react-router-dom";

export default function FeedbackDetails(props) {
  const data = props.location.state;
  const [loader, setLoader] = useState(false);
  const imgUrl = process.env.REACT_APP_IMAGE_BASEURL;

  return (
    <div>
      {/* <PageTitle
        activeMenu="Feedback Details"
        motherMenu="Feedback Management"
      /> */}
            <div className="page-titles">
        <h4>Feedback Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="feedback-list">Feedback Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Feedback Details</Link>
          </li>
        </ol>
      </div>
      {data
        ?.filter((item) => item?.text)
        .map((item, i) => (
          <Card className="mb-3" key={i}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6} className="mb-3 mb-md-0">
                  <div className="mb-3">
                    <h5>Question</h5>
                    <p className="fs-14">{item?.subject}</p>
                  </div>
                  <div>
                    <h5>Feedback</h5>
                    <p className="fs-14">{item?.text}</p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <Row>
                    {item?.images &&
                      item?.images?.map((image, index) => (
                        <Col xs={6} sm={4} key={index} className="mb-3">
                          <img
                            src={image?.url ? imgUrl + image?.url : dummy}
                            width="100%"
                            height="auto"
                            className="rounded"
                            alt="Feedback"
                          />
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}

      {loader && <Spinner />}
    </div>
  );
}
