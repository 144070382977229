import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import {
  CHANGE_PASSWORD,
  DASHBOARD,
  LOGIN,
  LOGOUT,
  POST_NOTIFICATION,
  TRIGGER_NOW,
} from "./ApiEndpoints";
import instance from "./Instance";
import AWS from "aws-sdk";

// export function signUp(email, password) {
//   //axios call

//   const postData = {
//     email,
//     password,
//     returnSecureToken: true,
//   };

//   return axios.post(
//     `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
//     postData
//   );
// }

export function login(email, password) {
  const postData = {
    email,
    password,
  };

  return instance.post(LOGIN, postData);
}
export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.put(CHANGE_PASSWORD, postData, { headers: myHeaders });
}
export function logOut() {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(
    LOGOUT,
    { test: "" },
    {
      headers: myHeaders,
    }
  );
}
export function dashboard() {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(DASHBOARD, {
    headers: myHeaders,
  });
}
export function triggerCard(quizId,type) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = {quizId:quizId, type:type };
  return instance.post(TRIGGER_NOW ,postData, {
    headers: myHeaders,
  });
}


export function pushNotification(title, body, ids, type, image) {
  const data = localStorage.getItem("closerDetails");
  const postData = {
    title,
    body,
    users: ids,
    image: image,
    type: type,
  };
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.post(POST_NOTIFICATION, postData, {
    headers: myHeaders,
  });
}

const NEXT_PUBLIC_COGNITO_POOL_ID = process.env.REACT_APP_BUCKET_POOL_ID;
const NEXT_PUBLIC_COGNITO_POOL_REGION = process.env.REACT_APP_BUCKET_REGION;
const NEXT_PUBLIC_S3_BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;

AWS.config.update({
  region: NEXT_PUBLIC_COGNITO_POOL_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: NEXT_PUBLIC_COGNITO_POOL_ID,
  }),
});

export default async function addPhoto(image, albumName) {
  console.log(image, albumName, "file in test files");
  var file = image;
  var fileName = Math.floor(Math.random() * 10000000) + file.name;
  var albumPhotosKey = encodeURIComponent(albumName) + "/";
  var photoKey = albumPhotosKey + fileName;

  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: NEXT_PUBLIC_S3_BUCKET_NAME,
      Key: photoKey,
      Body: file,
      ACL: "public-read",
    },
  });

  var promise = await upload.promise();
  promise.imageName = fileName;
  console.log(promise.imageName, "promise 1111111111111111");
  return promise;
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  // tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000,
  // );
  localStorage.setItem("closerDetails", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  //   const tokenDetailsString = localStorage.getItem("closerDetails");
  //   let tokenDetails = "";
  //   if (!tokenDetailsString) {
  //     dispatch(logout(history));
  //     return;
  //   }
  //   tokenDetails = JSON.parse(tokenDetailsString);
  //   let expireDate = new Date(tokenDetails.expireDate);
  //   let todaysDate = new Date();
  //   if (todaysDate > expireDate) {
  //     dispatch(logout(history));
  //     return;
  //   }
  //   dispatch(loginConfirmedAction(tokenDetails));
  //   const timer = expireDate.getTime() - todaysDate.getTime();
  //   runLogoutTimer(dispatch, timer, history);
}
