import React, { useEffect, useState } from "react";

import {
  getFirstFrequency,
  getFrequency,
  postFistFrequency,
  postFrequency,
} from "../../services/Quiz/QuizService";

import PageTitle from "../layouts/PageTitle";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import Spinner from "../components/Common/Spinner";

export default function FreQuency(props) {
  const [firstFrequency, setFirstFrequency] = useState("");
  const [frequency, setFrequency] = useState("");
  const [loader, setLoader] = useState(false);
  let errorsObj = { frequency: "", firstFrequency: "" };
  const [errors, setErrors] = useState(errorsObj);

  function fetchFirstFrequency() {
    setLoader(true);
    getFirstFrequency()
      .then((response) => {
        setFirstFrequency(response.data.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
        if (error?.response?.data?.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }
  function onCreateFirst(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (firstFrequency === "") {
      errorObj.firstFrequency = "";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    postFistFrequency(firstFrequency)
      .then((response) => {
        // setFirstFrequency("");
        setLoader(false);
        notifyTopRight("Created Successfully.");
        console.log(response, "login reponse");
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error.response?.data?.message);
        console.log(error, "Login error");
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }
  function fetchFrequency() {
    setLoader(true);
    getFrequency()
      .then((response) => {
        setFrequency(response.data.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
        if (error?.response?.data?.statusCode === 401) {
          localStorage.removeItem("closerDetails");
          props.history.push("/");
        }
      });
  }

  function onCreate(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (frequency === "") {
      errorObj.frequency = "Please select an option!";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);

    postFrequency(frequency)
      .then((response) => {
        // setFrequency("");
        setLoader(false);
        notifyTopRight("Created Successfully.");
        console.log(response, "login reponse");
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error.response?.data?.message);
        console.log(error, "Login error");
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }

  useEffect(() => {
    fetchFirstFrequency();
    fetchFrequency();
  }, []);
  return (
    <div>
      <PageTitle activeMenu="QnA Frequency" motherMenu="QnA Cards" />
      <div className="col-lg-12 mb-5">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Set First Frequency</h3>
          </div>
          <form onSubmit={onCreateFirst}>
            <div className="form-group">
              <label className="mb-2 ">Hours</label>

              <div className="">
                <input
                  type="number"
                  min={0}
                  className="form-control"
                  value={firstFrequency}
                  onChange={(e) => setFirstFrequency(e.target.value)}
                ></input>
              </div>
              {errors.firstFrequency && (
                <div className="text-danger fs-12">{errors.firstFrequency}</div>
              )}
            </div>

            <div className="text-right ">
              <button
                type="submit"
                className="btn btn-primary text-white   mr-3"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-12 mb-5">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">
              Set Subsequent Frequencies
            </h3>
          </div>
          <form onSubmit={onCreate}>
            <div className="form-group">
              <label className="mb-2 ">Hours</label>

              <div className="">
                <input
                  type="number"
                  min={0}
                  className="form-control"
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                ></input>
              </div>
              {errors.frequency && (
                <div className="text-danger fs-12">{errors.frequency}</div>
              )}
            </div>

            <div className="text-right ">
              <button
                type="submit"
                className="btn btn-primary text-white   mr-3"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {loader && <Spinner />}
    </div>
  );
}
